export const APISettings = {
  publicBaseUrl: '/public-api/',
  baseUrl: '/api/',
};

function objToQueryString(obj: any) {
  const keyValuePairs = [];
  for (const key in obj) {
    if (obj[key]) keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return keyValuePairs.join('&');
}

export class PublicAPIClient {
  static async Post<T>(relativeUrl: string, data: any): Promise<T> {
    let response = await fetch(`${APISettings.publicBaseUrl}${relativeUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return (await response.json()) as Promise<T>;
  }

  static async Get<T>(relativeUrl: string, payload?: any): Promise<T> {
    let completeUrl = `${APISettings.publicBaseUrl}${relativeUrl}`;

    if (payload) {
      const queryString = objToQueryString(payload);
      completeUrl += '?' + queryString;
    }

    const response = await fetch(completeUrl);
    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return (await response.json()) as Promise<T>;
  }

  static async Put<T>(relativeUrl: string, data: any): Promise<T> {
    let response = await fetch(`${APISettings.baseUrl}${relativeUrl}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return (await response.json()) as Promise<T>;
  }
}

export class APIClient {
  static async Post<T>(relativeUrl: string, data: any): Promise<T> {
    let response = await fetch(`${APISettings.baseUrl}${relativeUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return Promise.reject(await response.json());
    }

    return (await response.json()) as Promise<T>;
  }

  static async Get<T>(relativeUrl: string, payload?: any): Promise<T> {
    let completeUrl = `${APISettings.baseUrl}${relativeUrl}`;

    if (payload) {
      const queryString = objToQueryString(payload);
      completeUrl += '?' + queryString;
    }

    const response = await fetch(completeUrl);

    if (!response.ok) {
      return Promise.reject({
        responseParams: { status: response.status, statusText: response.statusText },
        ...(await response.json()),
      });
    }

    return (await response.json()) as Promise<T>;
  }

  static async AuthorizedGet<T>(bearerToken: string, relativeUrl: string, payload?: any): Promise<T> {
    let completeUrl = `${APISettings.baseUrl}${relativeUrl}`;

    if (payload) {
      const queryString = objToQueryString(payload);
      completeUrl += '?' + queryString;
    }

    const response = await fetch(completeUrl, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    });

    if (!response.ok) {
      return Promise.reject({
        responseParams: { status: response.status, statusText: response.statusText },
        ...(await response.json()),
      });
    }

    return (await response.json()) as Promise<T>;
  }

  static async AuthorizedPost<T>(bearerToken: string, relativeUrl: string, data?: any): Promise<T> {
    let response = await fetch(`${APISettings.baseUrl}${relativeUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
      body: data ? JSON.stringify(data) : undefined,
    });
    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return (await response.json()) as Promise<T>;
  }

  static async AuthorizedFormPost<T>(bearerToken: string, relativeUrl: string, form: FormData): Promise<T> {
    let response = await fetch(`${APISettings.baseUrl}${relativeUrl}`, {
      method: 'POST',
      headers: {
        //'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${bearerToken}`,
      },
      body: form,
    });
    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return (await response.json()) as Promise<T>;
  }

  static async Put<T>(relativeUrl: string, data: any): Promise<T> {
    let response = await fetch(`${APISettings.baseUrl}${relativeUrl}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return Promise.reject(await response.json());
    }

    return (await response.json()) as Promise<T>;
  }

  static async AuthorizedPut<T>(bearerToken: string, relativeUrl: string, data: any): Promise<T> {
    let response = await fetch(`${APISettings.baseUrl}${relativeUrl}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return (await response.json()) as Promise<T>;
  }

  static async AuthorizedPatch<T>(bearerToken: string, relativeUrl: string, data: any): Promise<T> {
    let response = await fetch(`${APISettings.baseUrl}${relativeUrl}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return (await response.json()) as Promise<T>;
  }

  static async AuthorizedDelete<T>(bearerToken: string, relativeUrl: string, data: any = {}): Promise<T> {
    let response = await fetch(`${APISettings.baseUrl}${relativeUrl}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return (await response.json()) as Promise<T>;
  }

  static async AuthorizedFormPut<T>(bearerToken: string, relativeUrl: string, form: FormData): Promise<T> {
    let response = await fetch(`${APISettings.baseUrl}${relativeUrl}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      body: form,
    });
    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return (await response.json()) as Promise<T>;
  }

  static async Delete<T>(relativeUrl: string): Promise<T> {
    let response = await fetch(`${APISettings.baseUrl}${relativeUrl}`, {
      method: 'DELETE',
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return (await response.json()) as Promise<T>;
  }
}
